<template>
  <div class="installation">

    <light-box :images="images" :index="index" @close="index = null" />

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Installation
        </h1>
      </div>
    </div>

     <div class="content-container page-text">
      <div class="columns">
        <div class="column">
          <p>
            We offer a full on-site installation service for all equipment manufactured by us or vendor supplied. All installation works are carefully planned, well communicated and delivered safely and efficiently.
          </p>

          <p>
            Services include:
          </p>

          <div class="content">
            <ul>
              <li>Comprehensive JSEA and SWMS documentation</li>
              <li>Project Management and contractor co-ordination</li>
              <li>Supervision</li>
              <li>Tailored site workshop set up</li>
              <li>Technical machine installations</li>
              <li>Site run piping</li>
            </ul>
          </div>
        </div>
        <div class="column is-5">
           <div class="pad shadow">
            <b-carousel
              :autoplay="true"
              :indicator="false"
              :pause-hover="false"
              :arrow="true"
              icon-pack="far"
              icon-next="chevron-right"
              :arrow-hover="false">
              <b-carousel-item v-for="(image, i) in images" :key="i">
                <a class="image is-4by5" @click="index = i">
                  <img class="bg-fff" :src="image">
                </a>
              </b-carousel-item>
            </b-carousel>
          </div>
        </div>
      </div>
     </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      index: null,
      images: [
        require('@/assets/img/installation/1.jpg'),
        require('@/assets/img/installation/2.png'),
        require('@/assets/img/installation/3.png')
      ]
    }
  }
}
</script>
